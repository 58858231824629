body {
  background: linear-gradient(157deg, #e2e8eb 10%, #e6a48f 70%, #3d94b0 20%,#e2e8eb 18%, #e6a48f 70%, #3d94b0 88%);
  margin: 0;
  font-family: Arial, sans-serif;
  padding: 20px;
  min-height: 100vh;
}

/* h1 {
  font-size: 2.5rem;
  margin: 0;
  padding: 1rem;
}

.ant-card {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ant-card-body {
  padding: 16px;
}

.ant-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.ant-col {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media (max-width: 576px) {
  h1 {
    font-size: 2rem;
  }

  .ant-card {
    margin: 10px 0;
  }
} */